import { template as template_a96c760f04ed4b62bf6d017140625809 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_a96c760f04ed4b62bf6d017140625809(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
