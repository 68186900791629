import { template as template_fcc8f6aaf5e749bd89026d077b6163ca } from "@ember/template-compiler";
const FKLabel = template_fcc8f6aaf5e749bd89026d077b6163ca(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
